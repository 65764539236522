export const defaultLanguage = 'sg-en';

export const defaultDateFormat = 'DD-MM-YYYY';

export const languages = ['en',
  'hk-zh',
  'tw-zh',
  'vn-vi',
  'id-id',
  'th-th',
  'sg-en',
  'my-en',
  'hk-en',
  'tw-en',
  'jp-ja'
];
export const contentLanguageMap = {
  'sg-en': 'en',
  en: 'en',
  'my-en': 'en_MY',
  'hk-zh': 'zh_HK',
  'hk-en': 'en_HK',
  'tw-en': 'en_TW',
  'tw-zh': 'zh_TW',
  'id-id': 'id_ID',
  'th-th': 'th_TH',
  'vn-vi': 'vi',
  'jp-ja': 'ja_JP'
};

export const defaultLanguageMap = {
  sg: ['en'],
  my: ['en'],
  hk: ['zh', 'en'],
  th: ['th'],
  id: ['id'],
  tw: ['zh'],
  vn: ['vi'],
  jp: ['ja']
};

export const languageMap = {
  en: 'English',
  id: 'Indonesian',
  ms: 'Malay',
  zh: 'Chinese',
  th: 'Thai',
  vi: 'Vietnamese',
  ja: 'Japanese'
};

export const countryMap = {
  sg: 'Singapore',
  my: 'Malaysia',
  vn: 'Việt Nam',
  hk: 'Hong Kong',
  th: 'Thailand',
  id: 'Indonesia',
  tw: 'Taiwan',
  jp: 'Japan'
};

export const hrefLanguages = {
  'hk-zh': 'zh-HK',
  'tw-zh': 'zh-TW',
  'vn-vi': 'vi-VN',
  'id-id': 'id-ID',
  'th-th': 'th-TH',
  'sg-en': 'en-SG',
  'my-en': 'en-MY',
  'hk-en': 'en-HK',
  'jp-ja': 'ja-JP'
};

export const phoneCodeMapping = {
  SG: 65,
  MY: 60,
  VN: 84,
  HK: 852,
  TH: 66,
  ID: 62,
  TW: 886,
  JP: 81
};

export const countryTimeZoneMap = {
  sg: 'Asia/Singapore',
  my: 'Asia/Kuala_Lumpur',
  vn: 'Asia/Ho_Chi_Minh',
  hk: 'Asia/Hong_Kong',
  th: 'Asia/Bangkok',
  id: 'Asia/Jakarta',
  tw: 'Asia/Taipei',
  jp: 'Asia/Tokyo'
};

export const countryLanguageList = {
  'sg-en': { country: 'Singapore', language: 'English' },
  'my-en': { country: 'Malaysia', language: 'English' },
  'vn-vi': { country: 'Việt Nam', language: 'Tiếng Việt' },
  'hk-zh': { country: ' 香港', language: '繁體中文' },
  'hk-en': { country: 'Hong Kong', language: 'English' },
  'th-th': { country: 'ประเทศไทย', language: 'ไทย' },
  'tw-zh': { country: '台湾', language: '繁體中文' },
  'id-id': { country: 'Indonesia', language: 'Indonesian' },
  'jp-ja': { country: '日本', language: '日本語' }
};

export const countryDialCodeData = {
  sg: { name: 'Singapore', dialCode: '+65' },
  hk: { name: 'Hong Kong (香港)', dialCode: '+852' },
  mo: { name: 'Macao', dialCode: '+853' },
  my: { name: 'Malaysia', dialCode: '+60' },
  id: { name: 'Indonesia', dialCode: '+62' },
  tw: { name: 'Taiwan (台灣)', dialCode: '+886' },
  th: { name: 'Thailand (ไทย)', dialCode: '+66' },
  vn: { name: 'Vietnam (Việt Nam)', dialCode: '+84' },
  jp: { name: 'Japan', dialCode: '+81' },
  af: { name: 'Afghanistan', dialCode: '+93' },
  ax: { name: 'Aland Islands', dialCode: '+358' },
  al: { name: 'Albania', dialCode: '+355' },
  dz: { name: 'Algeria', dialCode: '+213' },
  as: { name: 'AmericanSamoa', dialCode: '+1684' },
  ad: { name: 'Andorra', dialCode: '+376' },
  ao: { name: 'Angola', dialCode: '+244' },
  ai: { name: 'Anguilla', dialCode: '+1264' },
  aq: { name: 'Antarctica', dialCode: '+672' },
  ag: { name: 'Antigua and Barbuda', dialCode: '+1268' },
  ar: { name: 'Argentina', dialCode: '+54' },
  am: { name: 'Armenia', dialCode: '+374' },
  aw: { name: 'Aruba', dialCode: '+297' },
  au: { name: 'Australia', dialCode: '+61' },
  at: { name: 'Austria', dialCode: '+43' },
  az: { name: 'Azerbaijan', dialCode: '+994' },
  bs: { name: 'Bahamas', dialCode: '+1242' },
  bh: { name: 'Bahrain', dialCode: '+973' },
  bd: { name: 'Bangladesh', dialCode: '+880' },
  bb: { name: 'Barbados', dialCode: '+1246' },
  by: { name: 'Belarus', dialCode: '+375' },
  be: { name: 'Belgium', dialCode: '+32' },
  bz: { name: 'Belize', dialCode: '+501' },
  bj: { name: 'Benin', dialCode: '+229' },
  bm: { name: 'Bermuda', dialCode: '+1441' },
  bt: { name: 'Bhutan', dialCode: '+975' },
  bo: { name: 'Bolivia, Plurinational State of', dialCode: '+591' },
  ba: { name: 'Bosnia and Herzegovina', dialCode: '+387' },
  bw: { name: 'Botswana', dialCode: '+267' },
  br: { name: 'Brazil', dialCode: '+55' },
  io: { name: 'British Indian Ocean Territory', dialCode: '+246' },
  bn: { name: 'Brunei Darussalam', dialCode: '+673' },
  bg: { name: 'Bulgaria', dialCode: '+359' },
  bf: { name: 'Burkina Faso', dialCode: '+226' },
  bi: { name: 'Burundi', dialCode: '+257' },
  kh: { name: 'Cambodia', dialCode: '+855' },
  cm: { name: 'Cameroon', dialCode: '+237' },
  ca: { name: 'Canada', dialCode: '+1' },
  cv: { name: 'Cape Verde', dialCode: '+238' },
  ky: { name: 'Cayman Islands', dialCode: '+ 345' },
  cf: { name: 'Central African Republic', dialCode: '+236' },
  td: { name: 'Chad', dialCode: '+235' },
  cl: { name: 'Chile', dialCode: '+56' },
  cn: { name: 'China', dialCode: '+86' },
  cx: { name: 'Christmas Island', dialCode: '+61' },
  cc: { name: 'Cocos (Keeling) Islands', dialCode: '+61' },
  co: { name: 'Colombia', dialCode: '+57' },
  km: { name: 'Comoros', dialCode: '+269' },
  cg: { name: 'Congo', dialCode: '+242' },
  cd: { name: 'Congo, The Democratic Republic of the Congo', dialCode: '+243' },
  ck: { name: 'Cook Islands', dialCode: '+682' },
  cr: { name: 'Costa Rica', dialCode: '+506' },
  ci: { name: 'Cote d\'Ivoire', dialCode: '+225' },
  hr: { name: 'Croatia', dialCode: '+385' },
  cu: { name: 'Cuba', dialCode: '+53' },
  cy: { name: 'Cyprus', dialCode: '+357' },
  cz: { name: 'Czech Republic', dialCode: '+420' },
  dk: { name: 'Denmark', dialCode: '+45' },
  dj: { name: 'Djibouti', dialCode: '+253' },
  dm: { name: 'Dominica', dialCode: '+1767' },
  do: { name: 'Dominican Republic', dialCode: '+1849' },
  ec: { name: 'Ecuador', dialCode: '+593' },
  eg: { name: 'Egypt', dialCode: '+20' },
  sv: { name: 'El Salvador', dialCode: '+503' },
  gq: { name: 'Equatorial Guinea', dialCode: '+240' },
  er: { name: 'Eritrea', dialCode: '+291' },
  ee: { name: 'Estonia', dialCode: '+372' },
  et: { name: 'Ethiopia', dialCode: '+251' },
  fk: { name: 'Falkland Islands (Malvinas)', dialCode: '+500' },
  fo: { name: 'Faroe Islands', dialCode: '+298' },
  fj: { name: 'Fiji', dialCode: '+679' },
  fi: { name: 'Finland', dialCode: '+358' },
  fr: { name: 'France', dialCode: '+33' },
  gf: { name: 'French Guiana', dialCode: '+594' },
  pf: { name: 'French Polynesia', dialCode: '+689' },
  ga: { name: 'Gabon', dialCode: '+241' },
  gm: { name: 'Gambia', dialCode: '+220' },
  ge: { name: 'Georgia', dialCode: '+995' },
  de: { name: 'Germany', dialCode: '+49' },
  gh: { name: 'Ghana', dialCode: '+233' },
  gi: { name: 'Gibraltar', dialCode: '+350' },
  gr: { name: 'Greece', dialCode: '+30' },
  gl: { name: 'Greenland', dialCode: '+299' },
  gd: { name: 'Grenada', dialCode: '+1473' },
  gp: { name: 'Guadeloupe', dialCode: '+590' },
  gu: { name: 'Guam', dialCode: '+1671' },
  gt: { name: 'Guatemala', dialCode: '+502' },
  gg: { name: 'Guernsey', dialCode: '+44' },
  gn: { name: 'Guinea', dialCode: '+224' },
  gw: { name: 'Guinea-Bissau', dialCode: '+245' },
  gy: { name: 'Guyana', dialCode: '+595' },
  ht: { name: 'Haiti', dialCode: '+509' },
  va: { name: 'Holy See (Vatican City State)', dialCode: '+379' },
  hn: { name: 'Honduras', dialCode: '+504' },
  hu: { name: 'Hungary', dialCode: '+36' },
  is: { name: 'Iceland', dialCode: '+354' },
  in: { name: 'India', dialCode: '+91' },
  ir: { name: 'Iran, Islamic Republic of Persian Gulf', dialCode: '+98' },
  iq: { name: 'Iraq', dialCode: '+964' },
  ie: { name: 'Ireland', dialCode: '+353' },
  im: { name: 'Isle of Man', dialCode: '+44' },
  il: { name: 'Israel', dialCode: '+972' },
  it: { name: 'Italy', dialCode: '+39' },
  jm: { name: 'Jamaica', dialCode: '+1876' },
  je: { name: 'Jersey', dialCode: '+44' },
  jo: { name: 'Jordan', dialCode: '+962' },
  kz: { name: 'Kazakhstan', dialCode: '+77' },
  ke: { name: 'Kenya', dialCode: '+254' },
  ki: { name: 'Kiribati', dialCode: '+686' },
  kp: { name: 'Korea, Democratic People\'s Republic of Korea', dialCode: '+850' },
  kr: { name: 'Korea, Republic of South Korea', dialCode: '+82' },
  kw: { name: 'Kuwait', dialCode: '+965' },
  kg: { name: 'Kyrgyzstan', dialCode: '+996' },
  la: { name: 'Laos', dialCode: '+856' },
  lv: { name: 'Latvia', dialCode: '+371' },
  lb: { name: 'Lebanon', dialCode: '+961' },
  ls: { name: 'Lesotho', dialCode: '+266' },
  lr: { name: 'Liberia', dialCode: '+231' },
  ly: { name: 'Libyan Arab Jamahiriya', dialCode: '+218' },
  li: { name: 'Liechtenstein', dialCode: '+423' },
  lt: { name: 'Lithuania', dialCode: '+370' },
  lu: { name: 'Luxembourg', dialCode: '+352' },
  mk: { name: 'Macedonia', dialCode: '+389' },
  mg: { name: 'Madagascar', dialCode: '+261' },
  mw: { name: 'Malawi', dialCode: '+265' },
  mv: { name: 'Maldives', dialCode: '+960' },
  ml: { name: 'Mali', dialCode: '+223' },
  mt: { name: 'Malta', dialCode: '+356' },
  mh: { name: 'Marshall Islands', dialCode: '+692' },
  mq: { name: 'Martinique', dialCode: '+596' },
  mr: { name: 'Mauritania', dialCode: '+222' },
  mu: { name: 'Mauritius', dialCode: '+230' },
  yt: { name: 'Mayotte', dialCode: '+262' },
  mx: { name: 'Mexico', dialCode: '+52' },
  fm: { name: 'Micronesia, Federated States of Micronesia', dialCode: '+691' },
  md: { name: 'Moldova', dialCode: '+373' },
  mc: { name: 'Monaco', dialCode: '+377' },
  mn: { name: 'Mongolia', dialCode: '+976' },
  me: { name: 'Montenegro', dialCode: '+382' },
  ms: { name: 'Montserrat', dialCode: '+1664' },
  ma: { name: 'Morocco', dialCode: '+212' },
  mz: { name: 'Mozambique', dialCode: '+258' },
  mm: { name: 'Myanmar', dialCode: '+95' },
  na: { name: 'Namibia', dialCode: '+264' },
  nr: { name: 'Nauru', dialCode: '+674' },
  np: { name: 'Nepal', dialCode: '+977' },
  nl: { name: 'Netherlands', dialCode: '+31' },
  an: { name: 'Netherlands Antilles', dialCode: '+599' },
  nc: { name: 'New Caledonia', dialCode: '+687' },
  nz: { name: 'New Zealand', dialCode: '+64' },
  ni: { name: 'Nicaragua', dialCode: '+505' },
  ne: { name: 'Niger', dialCode: '+227' },
  ng: { name: 'Nigeria', dialCode: '+234' },
  nu: { name: 'Niue', dialCode: '+683' },
  nf: { name: 'Norfolk Island', dialCode: '+672' },
  mp: { name: 'Northern Mariana Islands', dialCode: '+1670' },
  no: { name: 'Norway', dialCode: '+47' },
  om: { name: 'Oman', dialCode: '+968' },
  pk: { name: 'Pakistan', dialCode: '+92' },
  pw: { name: 'Palau', dialCode: '+680' },
  ps: { name: 'Palestinian Territory, Occupied', dialCode: '+970' },
  pa: { name: 'Panama', dialCode: '+507' },
  pg: { name: 'Papua New Guinea', dialCode: '+675' },
  py: { name: 'Paraguay', dialCode: '+595' },
  pe: { name: 'Peru', dialCode: '+51' },
  ph: { name: 'Philippines', dialCode: '+63' },
  pn: { name: 'Pitcairn', dialCode: '+872' },
  pl: { name: 'Poland', dialCode: '+48' },
  pt: { name: 'Portugal', dialCode: '+351' },
  pr: { name: 'Puerto Rico', dialCode: '+1939' },
  qa: { name: 'Qatar', dialCode: '+974' },
  ro: { name: 'Romania', dialCode: '+40' },
  ru: { name: 'Russia', dialCode: '+7' },
  rw: { name: 'Rwanda', dialCode: '+250' },
  re: { name: 'Reunion', dialCode: '+262' },
  bl: { name: 'Saint Barthelemy', dialCode: '+590' },
  sh: { name: 'Saint Helena, Ascension and Tristan Da Cunha', dialCode: '+290' },
  kn: { name: 'Saint Kitts and Nevis', dialCode: '+1869' },
  lc: { name: 'Saint Lucia', dialCode: '+1758' },
  mf: { name: 'Saint Martin', dialCode: '+590' },
  pm: { name: 'Saint Pierre and Miquelon', dialCode: '+508' },
  vc: { name: 'Saint Vincent and the Grenadines', dialCode: '+1784' },
  ws: { name: 'Samoa', dialCode: '+685' },
  sm: { name: 'San Marino', dialCode: '+378' },
  st: { name: 'Sao Tome and Principe', dialCode: '+239' },
  sa: { name: 'Saudi Arabia', dialCode: '+966' },
  sn: { name: 'Senegal', dialCode: '+221' },
  rs: { name: 'Serbia', dialCode: '+381' },
  sc: { name: 'Seychelles', dialCode: '+248' },
  sl: { name: 'Sierra Leone', dialCode: '+232' },
  sk: { name: 'Slovakia', dialCode: '+421' },
  si: { name: 'Slovenia', dialCode: '+386' },
  sb: { name: 'Solomon Islands', dialCode: '+677' },
  so: { name: 'Somalia', dialCode: '+252' },
  za: { name: 'South Africa', dialCode: '+27' },
  ss: { name: 'South Sudan', dialCode: '+211' },
  gs: { name: 'South Georgia and the South Sandwich Islands', dialCode: '+500' },
  es: { name: 'Spain', dialCode: '+34' },
  lk: { name: 'Sri Lanka', dialCode: '+94' },
  sd: { name: 'Sudan', dialCode: '+249' },
  sr: { name: 'Suriname', dialCode: '+597' },
  sj: { name: 'Svalbard and Jan Mayen', dialCode: '+47' },
  sz: { name: 'Swaziland', dialCode: '+268' },
  se: { name: 'Sweden', dialCode: '+46' },
  ch: { name: 'Switzerland', dialCode: '+41' },
  sy: { name: 'Syrian Arab Republic', dialCode: '+963' },
  tj: { name: 'Tajikistan', dialCode: '+992' },
  tz: { name: 'Tanzania, United Republic of Tanzania', dialCode: '+255' },
  tl: { name: 'Timor-Leste', dialCode: '+670' },
  tg: { name: 'Togo', dialCode: '+228' },
  tk: { name: 'Tokelau', dialCode: '+690' },
  to: { name: 'Tonga', dialCode: '+676' },
  tt: { name: 'Trinidad and Tobago', dialCode: '+1868' },
  tn: { name: 'Tunisia', dialCode: '+216' },
  tr: { name: 'Turkey', dialCode: '+90' },
  tm: { name: 'Turkmenistan', dialCode: '+993' },
  tc: { name: 'Turks and Caicos Islands', dialCode: '+1649' },
  tv: { name: 'Tuvalu', dialCode: '+688' },
  ug: { name: 'Uganda', dialCode: '+256' },
  ua: { name: 'Ukraine', dialCode: '+380' },
  ae: { name: 'United Arab Emirates', dialCode: '+971' },
  gb: { name: 'United Kingdom', dialCode: '+44' },
  us: { name: 'United States', dialCode: '+1' },
  uy: { name: 'Uruguay', dialCode: '+598' },
  uz: { name: 'Uzbekistan', dialCode: '+998' },
  vu: { name: 'Vanuatu', dialCode: '+678' },
  ve: { name: 'Venezuela, Bolivarian Republic of Venezuela', dialCode: '+58' },
  vg: { name: 'Virgin Islands, British', dialCode: '+1284' },
  vi: { name: 'Virgin Islands, U.S.', dialCode: '+1340' },
  wf: { name: 'Wallis and Futuna', dialCode: '+681' },
  ye: { name: 'Yemen', dialCode: '+967' },
  zm: { name: 'Zambia', dialCode: '+260' },
  zw: { name: 'Zimbabwe', dialCode: '+263' }
};

export const countryDialCodeDataRequired = {
  sg: { name: 'Singapore', dialCode: '+65' },
  hk: { name: 'Hong Kong (香港)', dialCode: '+852' },
  my: { name: 'Malaysia', dialCode: '+60' },
  id: { name: 'Indonesia', dialCode: '+62' },
  tw: { name: 'Taiwan (台灣)', dialCode: '+886' },
  th: { name: 'Thailand (ไทย)', dialCode: '+66' },
  vn: { name: 'Vietnam (Việt Nam)', dialCode: '+84' },
  jp: { name: 'Japan', dialCode: '+81' }
};

export const timeZones = {
  SGD: {
    area: 'Asia/Singapore',
    zone: 'SGT'
  },
  MYR: {
    area: 'Asia/Kuala_Lumpur',
    zone: 'MYT'
  },
  HKD: {
    area: 'Asia/Hong_Kong',
    zone: 'HKT'
  },
  MOP: {
    area: 'Asia/Macau',
    zone: 'CST'
  },
  VND: {
    area: 'Asia/Ho_Chi_Minh',
    zone: 'ICT'
  },
  JPY: {
    area: 'Asia/Tokyo',
    zone: 'JST'
  },
  THB: {
    area: 'Asia/Bangkok',
    zone: 'THA'
  },
  TWD: {
    area: 'Asia/Taipei',
    zone: 'CST'
  }
};
export const countryData = {
  sg: { name: 'Singapore', dialCode: '+65' },
  hk: { name: 'Hong Kong (香港)', dialCode: '+852' },
  my: { name: 'Malaysia', dialCode: '+60' },
  id: { name: 'Indonesia', dialCode: '+62' },
  tw: { name: 'Taiwan (台灣)', dialCode: '+886' },
  th: { name: 'Thailand (ประเทศไทย)', dialCode: '+66' },
  vn: { name: 'Vietnam (Việt Nam)', dialCode: '+84' },
  jp: { name: 'Japan', dialCode: '+81' }
};
